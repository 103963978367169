import React, { useState, useEffect } from 'react';
import { BoxSelection, BoxSize } from 'wix-ui-tpa/BoxSelection';
import { Text } from 'wix-ui-tpa/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import {
  SlotsSelectionViewModel,
  TimeSlot,
} from '../../ViewModel/slotsSelectionViewModel/slotsSelectionViewModel';
import { classes, st } from './SlotsSelection.st.css';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { DataHooks, SlotsSelectionDisplayMode } from './constants';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';

export type SlotsSelectionProps = {
  viewModel: SlotsSelectionViewModel;
  displayMode: SlotsSelectionDisplayMode;
  date: string;
};

export const TIME_DELAY_BEFORE_FOCUS = 0;

const SlotsSelection: React.FC<SlotsSelectionProps> = ({
  viewModel,
  displayMode,
  date,
}) => {
  const {
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed,
    maxNumberOfTimeSlotsToDisplay,
    showAllButtonText,
    highlightedSlotDetails,
  } = viewModel;
  const boxSelectionRef = React.useRef(null as any);
  const { onTimeSelected, onElementClicked } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const [numberOfTimeSlotsToDisplay, setNumberOfTimeSlotsToDisplay] = useState(
    0,
  );
  const isUoUWeeklyCalendarOOILiveSiteEnabled = experiments.enabled(
    'specs.bookings.UoUWeeklyCalendarOOILiveSite',
  );
  const shouldDisplayShowAllButton =
    timeSlots.length > numberOfTimeSlotsToDisplay;
  const defaultNumberOfSlotsToDisplay = shouldLimitNumberOfTimeSlotsDisplayed
    ? maxNumberOfTimeSlotsToDisplay
    : timeSlots.length;

  useEffect(() => {
    setNumberOfTimeSlotsToDisplay(defaultNumberOfSlotsToDisplay);
  }, [maxNumberOfTimeSlotsToDisplay, shouldLimitNumberOfTimeSlotsDisplayed]);

  useEffect(() => {
    if (highlightedSlotDetails.shouldFocusOnRender) {
      setTimeout(
        () =>
          boxSelectionRef.current?.focus?.(highlightedSlotDetails.slotIndex),
        TIME_DELAY_BEFORE_FOCUS,
      );
    }
  }, [highlightedSlotDetails]);

  const onShowAllClick = () => {
    setNumberOfTimeSlotsToDisplay(timeSlots.length);
    onElementClicked(
      WidgetComponents.TIME_PICKER,
      WidgetElements.SHOW_ALL_SESSIONS_BUTTON,
    );
  };

  useEffect(() => {
    if (isUoUWeeklyCalendarOOILiveSiteEnabled) {
      boxSelectionRef.current?.focus?.(defaultNumberOfSlotsToDisplay);
    }
  }, [numberOfTimeSlotsToDisplay]);

  return (
    <div className={st(classes.root, { isMobile, displayMode })}>
      <BoxSelection
        key={`${DataHooks.SlotsSelection}-${date}`}
        name={`${DataHooks.SlotsSelection}-${date}`}
        data-hook={`${DataHooks.SlotsSelection}-${date}`}
        className={classes.boxSelection}
        size={BoxSize.small}
        onChange={({ id }) => onTimeSelected(id)}
        ref={boxSelectionRef}
      >
        {timeSlots
          .slice(0, numberOfTimeSlotsToDisplay)
          .map((timeSlot: TimeSlot, index: number) => {
            const {
              selected,
              allSlotsAreFull,
              tooLateToBookAllSlots,
              tooEarlyToBookAllSlots,
              withWaitingList,
            } = timeSlot.status;
            const isTimeSlotFull = allSlotsAreFull && !withWaitingList;
            const isDisabled =
              isTimeSlotFull || tooLateToBookAllSlots || tooEarlyToBookAllSlots;
            return (
              <BoxSelection.Option
                key={index}
                id={timeSlot.rfcStartTime}
                className={classes.boxSelectionOption}
                aria-label={timeSlot.ariaLabel}
                checked={selected}
                disabled={isDisabled}
                unavailable={isTimeSlotFull || tooLateToBookAllSlots}
              >
                <Text
                  data-hook={`${DataHooks.TimeSlotText}-${index}`}
                  className={st(classes.boxSelectionText, {
                    isDisabled,
                  })}
                >
                  <div aria-hidden={true} className={classes.startTime}>
                    {timeSlot.formattedStartTime}
                  </div>
                  {withWaitingList ? (
                    <div aria-hidden={true} className={classes.waitingList}>
                      {viewModel.waitlistText}
                    </div>
                  ) : null}
                </Text>
              </BoxSelection.Option>
            );
          })}
      </BoxSelection>
      {shouldDisplayShowAllButton ? (
        <TextButton
          key={`${DataHooks.ShowAllButton}-${date}`}
          data-hook={`${DataHooks.ShowAllButton}-${date}`}
          priority={TEXT_BUTTON_PRIORITY.link}
          onClick={onShowAllClick}
          className={classes.showAllButton}
        >
          {showAllButtonText}
        </TextButton>
      ) : null}
    </div>
  );
};

export default SlotsSelection;
