import React from 'react';
import { classes } from './SlotsNotification.st.css';
import { SlotsNotificationViewModel } from '../../ViewModel/slotsNotificationViewModel/slotsNotificationViewModel';
import {
  SectionNotification,
  TEXT_BUTTON_PRIORITY,
  NOTIFICATION_TYPE,
} from 'wix-ui-tpa/SectionNotification';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { DataHooks } from './constants';

export type SlotsNotificationProps = {
  viewModel?: SlotsNotificationViewModel;
};

const SlotsNotification: React.FC<SlotsNotificationProps> = ({ viewModel }) => {
  const { onSlotsNotificationCtaClick } = useCalendarActions();

  return viewModel ? (
    <div className={classes.root}>
      <SectionNotification
        type={NOTIFICATION_TYPE.wired}
        className={classes.notification}
        data-hook={DataHooks.SlotsNotification}
      >
        <SectionNotification.Text className={classes.text}>
          {viewModel.messageText}
          {viewModel.ctaText ? (
            <div>
              <TextButton
                className={classes.cta}
                priority={TEXT_BUTTON_PRIORITY.link}
                data-hook={DataHooks.CTA}
                onClick={() =>
                  onSlotsNotificationCtaClick(viewModel.notificationType!)
                }
              >
                {viewModel.ctaText}
              </TextButton>
            </div>
          ) : null}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  ) : null;
};

export default SlotsNotification;
